<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="homepage-collaborations"
  >
    <div class="content">
      <h2 class="title">{{ slice.primary.title }}</h2>
      <p v-if="isMobile" class="copy">{{ slice.primary.copy }}</p>
      <Link
        class="link"
        :label="slice.primary.link_label"
        :link="slice.primary.link"
      />
    </div>
    <div class="collab-container">
      <p v-if="!isMobile" class="copy copy-desktop">{{ slice.primary.copy }}</p>
      <div class="collab-wrapper">
        <template v-for="collab in slice.primary.collaboration">
          <NuxtLink
            :to="collab.url"
            class="collab"
            @mouseenter="showCursor(collab.name)"
            @mouseleave="hideCursor()"
          >
            <ImageWithDetails
              :image="collab.image"
              :detail1="collab.name"
              :detail2="collab.role"
              :full="true"
            />
          </NuxtLink>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Link from "@/components/Link";
import ImageWithDetails from "@/components/homepage/ImageWithDetails";

import { useMobile } from "@/composables/useMobile";

import { type Content } from "@prismicio/client";

const { showCursor, hideCursor } = useCustomCursor();

const { isMobile } = useMobile();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
  getSliceComponentProps<Content.CollaborationsHomepageSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);

onBeforeUnmount(() => {
  hideCursor();
});

onUnmounted(() => {
  hideCursor();
});
</script>

<style lang="scss" scoped>
.homepage-collaborations {
  padding: $padding-mobile-big var(--padding-mobile) 6rem;
  background-color: $white;
  @include desktop {
    padding: 2.2rem $padding-desktop 14rem;
  }
}

.content {
  position: relative;
  margin-bottom: 4rem;
}

.title {
  @include title-medium;
  text-transform: uppercase;
  margin-bottom: 3.5rem;
  @include desktop {
    margin-bottom: 9.5rem;
  }
}

.copy {
  @include caption-medium;
  width: 23rem;
  @include desktop {
    width: auto;
  }
}

.link {
  margin-top: 1rem;
  @include desktop {
    position: absolute;
    top: 0;
    right: 0;
    height: fit-content;
  }
}

.collab-container {
  @include desktop {
    @include grid-desktop;
    justify-content: center;
    grid-auto-flow: dense;
  }
}

.copy-desktop {
  margin-bottom: 2rem;
  grid-row: 1;
  grid-column: 3 / span 2;
}

.collab-wrapper {
  @include desktop {
    grid-column: 3 / span 8;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 2.4rem;
    grid-row: 2;
  }
}

.collab {
  display: block;
  width: 100%;
  margin-bottom: 2rem;

  @include desktop {
    grid-column: span 4;
  }
}
</style>
